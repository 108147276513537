<template>
    <b-modal id="modal-gen-ai-data" :hide-footer="true" modal-class="modal-w-xl" title="GenAI Data">
        <div class="mb-3" v-if="startupData">
            <div class="card card--bordered card--info">
                <h5 class="heading5">Main DB</h5>
                <p>
                    <b>Clients:</b> {{startupData.main.clients}}
                </p>
                <p>
                    <b>Features:</b>
                    <markdown>
                        {{startupData.main.features}}
                    </markdown>

                </p>
            </div>

            <template v-if="startupData.gen_ai.exists">
                <div class="card card--bordered card--info">
                    <h5 class="heading5">GenAI DB</h5>

                        <p>
                            <b>Clients (status {{startupData.gen_ai.clients_status}}):</b> {{startupData.gen_ai.clients}}
                        </p>
                        
                        <p>
                            <b>Features (status {{startupData.gen_ai.features_status}}):</b> {{startupData.gen_ai.features}}
                        </p>
                </div>
            </template>
            <template v-else>
                <a v-if="false" @click="addAIJob" class="link">Set Clients and Features Gen AI job</a>
            </template>
            <div class="text-center">
                <PrimaryButton
                    caption="Sync from Perplexity"
                    loading-caption="Loading..."
                    :loading="ppxLoading"
                    @click="syncPpx"
                />
            </div>

        </div>
    </b-modal>
</template>

<script>
import startupsService from "@/services/startups.service";
import PrimaryButton from "../../../../frontend/src/components/ui/PrimaryButton";
import markdown from 'vue-markdown'

export default {
    data() {
        return {
            id: 0,
            startupData: null,
            ppxLoading: false,
        }
    },

    components: {
        PrimaryButton,
        markdown
    },

    methods: {
        open(id) {
            this.id = id;
            this.getData();
        },

        getData() {
            startupsService.getGenAiData(this.id).then(response => {
                this.startupData = response.data;
                this.$bvModal.show('modal-gen-ai-data');
            })
        },

        addAIJob() {
            startupsService.addGenAiJob(this.id).then(() => {
                this.getData();
            })
        },

        syncPpx() {
            if (confirm('Import clients and features from Perplexity AI?')) {
                this.ppxLoading = true;

                startupsService.syncPPX(this.id).then(() => {
                    this.getData();
                }).finally(() => {
                    this.ppxLoading = false;
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
