const arrayColumn = (array, column, group = null) => array.map(function(value) {
    if (group) {
        if (value[group] && typeof value[group][column] !== 'undefined') {
            return value[group][column];
        }
    }
    else {
        if (value && typeof value[column] !== 'undefined') {
            return value[column];
        }
    }
})

const findIndexByColumnValue = (array, columnName, columnValue) => {

    let foundIndex = -1
    array.forEach((elem, index) => {

        if (Array.isArray(columnName)) {
            let equals = true
            for (let i in columnName) {
                if (elem[columnName[i]] != columnValue[i]) {
                    equals = false
                    return false
                }
            }

            if (equals) {
                foundIndex = index;
                return false
            }
        }

        else {
            if (elem && elem[columnName] == columnValue) {
                foundIndex = index;
                return false
            }
        }
    });

    return foundIndex
}

const findIndexByColumnValueInGroup = (array, groupName, columnName, columnValue) => {
    let foundIndex = -1

    array.forEach((group, index) => {
        group[groupName].forEach(elem => {
            if (elem[columnName] == columnValue) {
                foundIndex = index;
                return false
            }
        });
    });

    return foundIndex
}

const exists = (array, columnName, columnValue) => {
    let pos = findIndexByColumnValue(array, columnName, columnValue)
    return pos >= 0
}

const inArray = (array, item) => {
    return array.indexOf(item) >= 0
}

const cloneArray = (array) => {
    let newArray = null

    if (Array.isArray(array)) {
        newArray = []
        array.forEach(item => {
            if (typeof item === 'object' || Array.isArray(item)) {
                newArray.push(cloneArray(item))
            } else {
                newArray.push(item)
            }
        })
    }
    else if (typeof array === 'object') {
        newArray = {}

        for (let i in array) {
            newArray[i] = cloneArray(array[i])
            if (typeof array[i] === 'object' || Array.isArray(array[i])) {
                newArray[i] = cloneArray(array[i])
            }
            else {
                newArray[i] = array[i]
            }
        }
    }

    return newArray
}

const arrayDiff = (array1, array2, columnName = "", groupName = null) => {
    var newArray = []

    array2 = arrayColumn(array2, columnName, groupName)

    if (Array.isArray(array1)) {
        array1.forEach(item => {
            if (array2.indexOf(item[columnName]) < 0) {
                newArray.push(item)
            }
        })
    }
    else {
        newArray = {}
        for (let i in array1) {
            if (array2.indexOf(i) < 0) {
                newArray[i] = array1[i]
            }
        }
    }

    return newArray
}

const addColumn = (array, columnName, columnValue) => {
    for (let i in array) {
        array[i][columnName] = columnValue
    }

    return array
}

const convertForSearch = (array) => {
    let newArray = []

    array.forEach(item => {
        if (typeof item.name !== 'undefined') {
            newArray.push({
                value: item.name,
                name: item.name,
            })
        }
        else {
            newArray.push({
                value: item,
                name: item,
            })
        }
    })

    return newArray
}

const convertForFilter = (array) => {
    let newArray = []

    array.forEach(item => {
        newArray.push({
            value: item.id,
            name: item.name,
        })
    })

    return newArray
}

const getSingleArrayFromAssoc = (array) => {
    let newArray = []
    for (let i in array) {
        array[i].forEach(item => {
            newArray.push(item)
        })
    }

    return newArray
}

const parseNumber = (array) => {
    let newArray = []

    array.forEach(item => {
        newArray.push(parseInt(item))
    })

    return newArray
}

export const groupObjectsBy = (array, prop) => {
    return Array.from(
        array.reduce(
            (entryMap, e) => entryMap.set(e[prop], [...entryMap.get(e[prop])||[], e]),
            new Map()
        ).values()
    );
}

const compareSimpleArrays = (a, b) => {
    return JSON.stringify(a) === JSON.stringify(b);
};

const fillObject = (object, filling = '') => {
    for (let i in object) {
        object[i] = filling
    }
}

export default {
    arrayColumn,
    findIndexByColumnValue,
    findIndexByColumnValueInGroup,
    exists,
    inArray,
    cloneArray,
    arrayDiff,
    addColumn,
    convertForSearch,
    convertForFilter,
    getSingleArrayFromAssoc,
    parseNumber,
    groupObjectsBy,
    compareSimpleArrays,
    fillObject,
}
