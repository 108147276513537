<script setup>
import {ref, defineExpose, watch, computed, reactive, getCurrentInstance} from "vue";
import multiselect from "vue-multiselect";
import enterprisesService from "@/services/enterprises.service";
import trackersService from "@/services/trackers.service";
import PrimaryButton from "../../../../frontend/src/components/ui/PrimaryButton.vue";
import {trackerTypes} from "../../../../frontend/src/constants/trackers";
import savedSearchesService from "@/services/saved-searches.service";
import ecosystemsService from "@/services/ecosystems.service";

const showModal = ref(false);
const sourceType = ref('enterprise');
const srcEnterprise = ref(null);
const srcEcosystem = ref(null);
const srcEnterprises = ref([]);
const srcEcosystems = ref([]);
const destEnterprise = ref(null);
const destEnterprises = ref([]);
const srcTracker = ref(null);
const srcSearch = ref(null);
const trackersList = ref([]);
const searchesList = ref([]);
const loading = ref(false);
const type = ref('tracker');
const notify = getCurrentInstance().proxy.$notify;

const form = reactive({
    name: '',
    copy_startups: false,
    copy_diligence_values: false,
});

const isTracker = computed(() => type.value === 'tracker');
const isSearch = computed(() => type.value === 'search');
const typeEnterprise = computed(() => sourceType.value === 'enterprise');
const typeEcosystem = computed(() => sourceType.value === 'ecosystem');

const canSave = computed(() =>
    (
        (isTracker.value && srcTracker.value) || (isSearch.value && srcSearch.value)
    )
    && form.name.length &&
    destEnterprise.value &&
    destEnterprise.value.id !== srcEnterprise.value?.id
);

watch(sourceType, value => {
    if (value === 'ecosystem') {
        type.value = 'tracker';
    }
})

watch([srcEnterprise, srcEcosystem, type], () => {
    srcTracker.value = null;
    srcSearch.value = null;

    if ((typeEnterprise.value && srcEnterprise.value) || (typeEcosystem.value && srcEcosystem.value)) {
        if (isTracker.value) {
            getTrackersList();
        } else {
            getSearchesList();
        }
    } else {
        trackersList.value = [];
        searchesList.value = [];
    }
});

watch([srcTracker, srcSearch], () => {
    if (isTracker.value) {
        if (srcTracker.value) {
            form.name = srcTracker.value.name;
        } else {
            form.name = '';
        }
    } else {
        if (srcSearch.value) {
            form.name = srcSearch.value.name;
        } else {
            form.name = '';
        }
    }
});

async function updateSrcEnterprises(search) {
    const {data} = await enterprisesService.searchWithTrackers(search);
    srcEnterprises.value = data;
}

async function updateSrcEcosystems(search) {
    const {data} = await ecosystemsService.searchWithTrackers(search);
    srcEcosystems.value = data;
}

async function updateDestEnterprises(search) {
    const {data} = await enterprisesService.searchAccounts(search);
    destEnterprises.value = data;
}

async function getTrackersList(search = '') {
    const {data} = await trackersService.search(
        sourceType.value,
        typeEnterprise.value ? srcEnterprise.value.id : srcEcosystem.value.id,
        search);

    trackersList.value = data;
}

async function getSearchesList(search = '') {
    const {data} = await savedSearchesService.search(srcEnterprise.value.id, search);
    searchesList.value = data;
}

async function save() {
    loading.value = true;

    try {
        if (isTracker.value) {
            await trackersService.duplicate(srcTracker.value.id, destEnterprise.value.id, form);
        } else {
            await savedSearchesService.duplicate(srcSearch.value.id, destEnterprise.value.id, form);
        }

        notify({
            group: 'app',
            type: 'success',
            duration: 10000,
            text: (isTracker.value ? 'Tracker' : 'Saved Search') + ' created',
        });
    } finally {
        loading.value = false;
    }
}

function tabChanged(tab) {
    type.value = tab === 0 ? 'tracker' : 'search';
    form.name = '';
}

function open() {
    showModal.value = true;
}

defineExpose({open});
</script>

<template>
    <b-modal v-model="showModal" title="Duplicate Feature">
        <form class="form">
            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Select Source Type</h5>

                <div class="input-radio mb-2">
                    <input type="radio" v-model="sourceType" id="type-enterprise" value="enterprise"/>
                    <label for="type-enterprise">Enterprise</label>
                </div>

                <div class="input-radio">
                    <input type="radio" v-model="sourceType" id="type-ecosystem" value="ecosystem"/>
                    <label for="type-ecosystem">Ecosystem</label>
                </div>
            </div>

            <div v-if="sourceType === 'enterprise'" class="form__item">
                <h5 class="heading5 heading5--secondary">Select Source Enterprise</h5>
                <multiselect
                    v-model="srcEnterprise"
                    placeholder="Select Company"
                    :options="srcEnterprises"
                    label="name"
                    class="multiselect-mid pl-2"
                    @search-change="updateSrcEnterprises"
                >
                </multiselect>
            </div>

            <div v-else class="form__item">
                <h5 class="heading5 heading5--secondary">Select Source Ecosystem</h5>
                <multiselect
                    v-model="srcEcosystem"
                    placeholder="Select Company"
                    :options="srcEcosystems"
                    label="name"
                    class="multiselect-mid pl-2"
                    @search-change="updateSrcEcosystems"
                >
                </multiselect>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Select Destination Enterprise</h5>
                <multiselect
                    v-model="destEnterprise"
                    placeholder="Select Company"
                    :options="destEnterprises"
                    label="name"
                    class="multiselect-mid pl-2"
                    @search-change="updateDestEnterprises"
                >
                </multiselect>
            </div>

            <b-tabs v-if="(typeEnterprise && srcEnterprise) || (typeEcosystem && srcEcosystem)" @activate-tab="tabChanged">
                <b-tab>
                    <template #title>
                        <span>Tracker</span>
                    </template>

                    <div class="form__item mt-2">
                        <h5 class="heading5 heading5--secondary">Select Source Tracker</h5>

                        <multiselect
                            v-model="srcTracker"
                            placeholder="Select Tracker"
                            :options="trackersList"
                            label="name"
                            class="multiselect-mid pl-2"
                            @search-change="getTrackersList"
                        >
                            <template slot="option" slot-scope="{ option }">{{option.name}}
                                <template v-if="option.startups_count">({{option.startups_count}})</template>
                            </template>
                        </multiselect>
                    </div>

                    <template v-if="srcTracker">
                        <h6 class="heading6 mb-1">Tracker Name*</h6>
                        <b-form-input class="form-control--mid mb-3" v-model="form.name"></b-form-input>

                        <template v-if="srcTracker.type === trackerTypes.PRIVATE">
                            <div class="input-checkbox">
                                <input id="copy-startups" type="checkbox" v-model="form.copy_startups">
                                <label for="copy-startups">Include startups in tracker</label>
                            </div>

                            <div v-if="form.copy_startups" class="input-checkbox">
                                <input id="copy-diligence-values" type="checkbox" v-model="form.copy_diligence_values">
                                <label for="copy-diligence-values">Include Diligence Values</label>
                            </div>
                        </template>
                    </template>
                </b-tab>

                <b-tab v-if="typeEnterprise">
                    <template #title>
                        <span>Saved Search</span>
                    </template>

                    <div class="form__item mt-2">
                        <h5 class="heading5 heading5--secondary">Select Source Saved Search</h5>

                        <multiselect
                            v-model="srcSearch"
                            placeholder="Select Saved Search"
                            :options="searchesList"
                            label="name"
                            class="multiselect-mid pl-2"
                            @search-change="getSearchesList"
                        />
                    </div>

                    <template v-if="srcSearch">
                        <h6 class="heading6 mb-1">Saved Search Name*</h6>
                        <b-form-input class="form-control--mid mb-3" v-model="form.name"></b-form-input>
                    </template>
                </b-tab>
            </b-tabs>
        </form>

        <template #modal-footer>
            <PrimaryButton
                :disabled="!canSave"
                caption="Create Duplicate"
                loading-caption="Creating Duplicate..."
                :loading="loading"
                @click="save"
            />
        </template>
    </b-modal>
</template>

<style scoped>

</style>
