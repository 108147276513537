import axios from '@/axios';

const RESOURCE_NAME = '/admin/trackers';

export default {
    getSharedTrackers(data) {
        return axios.post(`${RESOURCE_NAME}/get-shared-trackers`, data)
    },

    getUsers(id) {
        return axios.get(`${RESOURCE_NAME}/get-users?id=${id}`)
    },

    search(companyType, companyId, search) {
        return axios.get(`${RESOURCE_NAME}/search?company_type=${companyType}&company_id=${companyId}&search=${search}`);
    },

    duplicate(trackerId, enterpriseId, data) {
        return axios.post(`${RESOURCE_NAME}/duplicate?id=${trackerId}&enterprise_id=${enterpriseId}`, {data});
    },
}
