<template>
    <div id="app" class="app-admin">
        <header class="header" v-if="$store.getters.isLoggedIn">
            <input id="menu-toggle" type="checkbox">
            <label for="menu-toggle" class="menu-button"><span></span></label>

            <a href="/" class="logo" title="Switch Pitch"></a>

            <div class="header__navigation-wrapper">
            <div class="header__navigation">
                <ul class="menu">
                    <li class="menu__home"><router-link to="/" exact>Home</router-link></li>
                    <li class="menu__users" v-if="$store.state.userType === 'admin'"><router-link to="/users">Users</router-link></li>

                    <li class="menu__companies">
                        <b-dropdown text="Companies" class="dropdown-small">
                            <b-dropdown-item class="menu__companies__startups" v-if="$store.state.userType === 'admin' || $store.state.userType === 'startup_manager'" href="/startups"><span>Startups</span></b-dropdown-item>
                            <b-dropdown-item class="menu__companies__ecosystems" href="/ecosystems"><span>Ecosystems</span></b-dropdown-item>
                            <b-dropdown-item class="menu__companies__enterprises" v-if="$store.state.userType === 'admin'" href="/enterprises"><span>Enterprises</span></b-dropdown-item>
                        </b-dropdown>
                    </li>

                    <li class="menu__merge menu__connection" v-if="$store.state.userType === 'admin'">
                        <b-dropdown text="Connections" class="dropdown-small">
                            <b-dropdown-item href="/shared-trackers"><span>Shared Trackers</span></b-dropdown-item>
                            <b-dropdown-item href="/companies-connections"><span>Companies Connections</span></b-dropdown-item>
                            <b-dropdown-item href="/invites"><span>Invites</span></b-dropdown-item>
                        </b-dropdown>
                    </li>

                    <li v-if="$store.state.userType === 'admin'" class="menu__resources">
                        <b-dropdown text="Resources" class="dropdown-small">
                            <b-dropdown-item href="/helper-items"><span>Manage Help Widget</span></b-dropdown-item>
                            <b-dropdown-item href="/release-notes"><span>Release Notes</span></b-dropdown-item>
                            <b-dropdown-item href="/upcoming-features"><span>Roadmap</span></b-dropdown-item>
                        </b-dropdown>
                    </li>

                    <li class="menu__tools" v-if="$store.state.userType === 'admin' || $store.state.userType === 'startup_manager'">
                        <b-dropdown text="Tools" class="dropdown-small">
                            <b-dropdown-item v-if="$store.state.userType === 'admin'" class="menu__tools__merge" href="/merge"><span>Merge</span></b-dropdown-item>
                            <b-dropdown-item v-if="$store.state.userType === 'admin'" class="menu__tools__merge" href="/merge-by-similar-domains"><span>Merge By Similar Domains</span></b-dropdown-item>
                            <b-dropdown-item href="/blacklist"><span>Blacklist Keywords</span></b-dropdown-item>
                            <b-dropdown-item href="/whitelist"><span>Whitelist Keywords</span></b-dropdown-item>
                            <b-dropdown-item href="/suggestion-blacklist"><span>Suggestion Blacklist Keywords</span></b-dropdown-item>
                            <b-dropdown-item href="/startups-crawling"><span>Diffbot Startups Crawling</span></b-dropdown-item>
                            <b-dropdown-item href="/crawling-active-jobs"><span>Diffbot Crawling Active jobs</span></b-dropdown-item>
                            <b-dropdown-item v-if="$store.state.userType === 'admin'" href="/coupons"><span>Coupons</span></b-dropdown-item>
                            <b-dropdown-item v-if="$store.state.userType === 'admin'" href="/invite-ecosystem-requests"><span>Invite Ecosystem Requests</span></b-dropdown-item>
                            <b-dropdown-item v-if="$store.state.userType === 'admin'" href="/invite-enterprise-requests"><span>Invite Enterprise Requests</span></b-dropdown-item>
                            <b-dropdown-item v-if="$store.state.userType === 'admin'" href="/analyst/enterprises"><span>Analyst hours</span></b-dropdown-item>
                            <b-dropdown-item v-if="$store.state.userType === 'admin'" href="/diffbot-categories"><span>Map Diffbot Categories</span></b-dropdown-item>
                            <b-dropdown-item v-if="$store.state.userType === 'admin'" href="/clients"><span>Clients</span></b-dropdown-item>
                            <b-dropdown-item v-if="$store.state.userType === 'admin'" href="/clients-discovery"><span>Clients Discovery Tool</span></b-dropdown-item>
                            <b-dropdown-item v-if="$store.state.userType === 'admin'" href="/blacklisted-clients"><span>Clients Blacklist</span></b-dropdown-item>
                            <b-dropdown-item v-if="$store.state.userType === 'admin'" href="/users-search-history"><span>Users Search History</span></b-dropdown-item>
                            <b-dropdown-item v-if="$store.state.userType === 'admin'" @click="$refs.duplicateFeatureModal.open()"><span>Duplicate Feature</span></b-dropdown-item>
                        </b-dropdown>
                    </li>
                </ul>
            </div>
        </div>

            <div class="header__side">
                <div class="header__user" :title=$store.state.user.name>
                    <img :src="$store.state.user.avatar" alt=""/>
                </div>
                <div class="header__logout" v-if="$store.getters.isLoggedIn">
                    <a href="" @click="logout">
                        <svg style="width: 20px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sign-out-alt" class="svg-inline--fa fa-sign-out-alt fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"></path></svg>
                    </a>
                </div>
            </div>
        </header>

        <router-view/>

        <page-not-found v-if="$store.state.pageNotFound"></page-not-found>

        <DuplicateFeature
            ref="duplicateFeatureModal"
        />

        <notifications position="bottom center" width="70%" group="app"/>
    </div>
</template>

<script>

import user from '@/services/user.service';
import auth from "@/services/auth.service";
import categories from "@/services/categories.service";
import PageNotFound from "../../frontend/src/components/common/PageNotFound";
import DuplicateFeature from "@/components/trackers/DuplicateFeature.vue";

export default {
    components: {
        DuplicateFeature: DuplicateFeature,
        PageNotFound
    },

    methods: {
        logout() {
            this.$store.commit('logout')
            this.$router.push('/login')
        },

        proceed() {
            user.getCurrentUserData()
                .then(response => {
                    this.$store.commit('setUser', response.data)
                })
        },
    },
    mounted() {
        if (this.$store.getters.isLoggedIn) {

            //Check if token is valid
            auth.checkToken()
                .then((response) => {
                    if (response.data.status != 1) {
                        this.logout();
                    }
                    else {
                        this.proceed();
                    }
                })

            if (!this.$store.state.categories.length) {
                categories.getList().then(response => {
                    this.$store.commit('setCategories', response.data)
                })
            }
        }
    },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
